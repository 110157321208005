.main {
  display: flex;
  justify-content: center;
  gap: 50px;
  padding: 5rem 5rem;
  background-color: #efefef;
}

.main img {
  width: 50%;
  height: auto;
  border-radius: 30px;
}

.main p {
  font-size: 1.5vw;
}

.btn {
  width: 30vw;
}

@media (max-width: 880px) {
  .main {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 3rem;
  }

  .main h3 {
    text-align: left;
  }

  .main img {
    width: 100%;
    height: auto;
  }
}
