.services {
  margin-bottom: 5rem;
  padding: 2rem 5rem;
}

.service_container {
  display: flex;
  gap: 1rem;
  padding: 0 1rem;
}

.service_container img {
  border-radius: 20px;
  width: 100%;
  height: auto;
}

.service_items {
  flex: 1;
}

.btn {
  margin-left: 1rem;
  width: 30vw;
}

@media (max-width: 880px) {
  .service_container {
    display: flex;
    flex-wrap: wrap;
  }
}
