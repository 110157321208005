body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Raleway", "Montserrat", "PT Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1 {
  font-size: 5vw;
  font-weight: lighter;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: #ee1154;
  text-decoration: underline;
  text-underline-offset: 10px;
  transition: 500ms;
  /* opacity: 0.7; */
}

/* color: #ee1154 */
/* color: #efefef */

input {
  margin-bottom: 1rem;
  font-size: 22px;
  padding-left: 10px;
  width: 40vw;
  min-width: 400px;
}

label {
  font-size: 22px;
  display: block;
}

textarea {
  padding-top: 5px;
  padding-left: 10px;
  width: 40vw;
  height: 30vh;
  margin-bottom: 0.5rem;
  min-width: 400px;
}
