.button {
  background-color: #ee1154;
  color: white;
  border: 10px;
  border-radius: 20px;
  padding: 10px 0;
  font-size: 2vw;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #ff4d7a;
  cursor: pointer;
}
