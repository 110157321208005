.faqPage {
  padding: 5rem 5rem;
}

.faqPage h1 {
  color: #ee1154;
  margin-bottom: 5rem;
}

.faq {
  text-align: left;
}

.faq h3 {
  font-size: 30px;
}

.faq_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
