.BookNow_page {
  background-color: #efefef;
}

.BookNow_page h1 {
  text-align: center;
}

.bookNow {
  position: relative;
  margin-bottom: 2rem;
}

.bookNow img {
  width: 100%;
  height: auto;
  filter: brightness(0.7);
}

.text {
  transform: translateX(-50%);
  position: absolute;
  text-align: center;
  color: white;
  width: 90%;
  left: 50%;
  top: 20%;
  text-transform: uppercase;
}

.form_container {
  padding: 5rem;
}
.btn {
  width: 40vw;
}
