.hero {
  color: white;
  position: relative;
}

.hero img {
  max-width: 100%;
  height: auto;
  filter: brightness(0.5);
}

.hero_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  width: 70%;
}

.hero_text h1 {
  font-size: 7vw;
  margin: 0;
}

.hero_text p {
  font-size: 1.5vw;
}

.btn {
  width: 30vw;
}
