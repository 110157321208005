.form {
  background-color: #efefef;
  padding-left: 2rem;
  padding-bottom: 5rem;
  padding-top: 1rem;
}

.form h1 {
  color: #ee1154;
  font-size: 90px;
  font-weight: lighter;
  margin-bottom: 1rem;
}

.form input {
  margin-bottom: 1rem;
  font-size: 22px;
  padding-left: 10px;
  width: 40vw;
  min-width: 430px;
}

.form label {
  font-size: 22px;
}

.form textarea {
  padding-top: 5px;
  padding-left: 10px;
  width: 40vw;
  height: 30vh;
  margin-bottom: 0.5rem;
  min-width: 430px;
}

.btn {
  width: 30vw;
  min-width: 430px;
  font-size: 22px;
}
